<template>
    <div class="page createAddress">
        <div class="form">
            <div class="group">
                <div class="label">姓名</div>
                <div class="input">
                    <input type="text" placeholder="请输入收货人姓名" v-model="name"/>
                </div>
            </div>
            <div class="group">
                <div class="label">手机号码</div>
                <div class="input">
                    <input type="tel" placeholder="请输入手机号码" v-model="phone"/>
                </div>
            </div>
            <div class="group">
                <div class="label">所在区域</div>
                <div class="input" @click="showProvinceModal">
                    <div :class="areaId ? 'city':'cityNone'">{{areaId ? provinceName+cityName+areaName :'请选择所在省市区'}}</div>
                    <img class="icon" src="../assets/icon.png" alt=""/>
                </div>
            </div>
            <div class="group">
                <div class="label">所在区域</div>
                <div class="input">
                    <textarea v-model="address" placeholder="街道楼牌号等详细地址"></textarea>
                </div>
            </div>
            <div class="default" @click="changeIsDefault">
                <div class="box"><span :class="isDefault ? 'check':'noCheck'"></span></div>
                设为默认
            </div>
        </div>
        <div class="footer">
            <span @click="submitCreateAddress">保存</span>
        </div>
        <ProvinceModal :modal="provinceModal" v-on:closeModal="closeProvinceModal" v-on:submitModal="submitProvinceModal"/>
    </div>
</template>

<script>
    import {Toast} from "vant";
    import ProvinceModal from "../components/ProvinceModal";
    import {addressDetails, addUserAddress, getProductDetail, updateUserAddress} from "../api/data";
    import _ from "lodash";

    export default {
        name: "CreateAddress",
        components: {ProvinceModal},
        data: function () {
            return {
                name: '',
                phone: '',
                provinceName: '',
                cityName: '',
                areaName: '',
                provinceId: '',
                cityId: '',
                areaId: '',
                address: '',
                isDefault: false,
                provinceModal: false,
                addStatus: false
            }
        },
        async beforeMount() {
            await this.queryAddressDetail();
        },
        methods: {
            async queryAddressDetail() {
                let param = {
                    id: _.toInteger(this.$route.query.id),
                };
                let res = await addressDetails(param);
                if (_.toInteger(res.code) === 1) {
                    this.name = res.data.name;
                    this.phone = res.data.phone;
                    this.provinceName = res.data.province_name;
                    this.cityName = res.data.city_name;
                    this.areaName = res.data.area_name;
                    this.provinceId = res.data.province;
                    this.cityId = res.data.city;
                    this.areaId = res.data.area;
                    this.address = res.data.address;
                    this.isDefault = _.toInteger(res.data.default) === 1 ? true : false;
                } else {
                    Toast.fail(res.msg);
                }
            },
            changeIsDefault() {
                this.isDefault = !this.isDefault;
            },
            showProvinceModal() {
                this.provinceModal = true;
            },
            closeProvinceModal() {
                this.provinceModal = false;
            },
            submitProvinceModal(provinceId, cityId, areaId, provinceName, cityName, areaName) {
                console.log(provinceId, cityId, areaId, provinceName, cityName, areaName)
                if (provinceId === null || provinceId === '' || provinceId === 0) {
                    Toast.fail("请先选择所在省份");
                    return false;
                }
                if (cityId === null || cityId === '' || cityId === 0) {
                    Toast.fail("请先选择所在城市");
                    return false;
                }
                if (areaId === null || areaId === '' || areaId === 0) {
                    Toast.fail("请先选择所在区县");
                    return false;
                }
                this.provinceName = provinceName;
                this.cityName = cityName;
                this.areaName = areaName;
                this.provinceId = provinceId;
                this.cityId = cityId;
                this.areaId = areaId;
                this.provinceModal = false;
            },
            async submitCreateAddress() {
                if (this.name === null || this.name === '') {
                    Toast.fail("请先输入收货人姓名");
                    return false;
                }
                if (this.phone === null || this.phone === '') {
                    Toast.fail("请先输入手机号");
                    return false;
                }
                if (this.provinceId === null || this.provinceId === '' || this.cityId === null || this.cityId === '' || this.areaId === null || this.areaId === '') {
                    Toast.fail("请先选择所在省市区");
                    return false;
                }
                if (this.address === null || this.address === '') {
                    Toast.fail("请先输入详细地址");
                    return false;
                }

                this.addStatus = true;
                let param = {
                    name: this.name,
                    phone: this.phone,
                    province: this.provinceId,
                    city: this.cityId,
                    area: this.areaId,
                    default: this.isDefault ? 1 : 0,
                    address: this.address,
                };
                let res = {};
                if (this.$route.query.id) {
                    param.id = this.$route.query.id;
                    param.status = 2;
                    res = await updateUserAddress(param);
                } else {
                    res = await addUserAddress(param);
                }
                if (_.toInteger(res.code) === 1) {
                    this.addStatus = false;
                    await this.$router.back();
                } else {
                    this.addStatus = false;
                    Toast.fail(res.msg);
                }
            }
        }
    }
</script>

<style scoped>
    .createAddress {
        display: flex;
        flex-direction: column;
        width: 690px;
        min-height: 100vh;
        justify-content: flex-start;
        align-items: center;
        padding: 24px;
        margin-bottom: 120px;
        box-sizing: border-box;
    }

    .createAddress .form {
        width: 642px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background: #FFFFFF;
        border-radius: 16px;
        padding: 30px;
        box-sizing: border-box;
    }

    .createAddress .form .group {
        width: 580px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 20px;
    }

    .createAddress .form .default {
        width: 580px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 30px;
        font-size: 24px;
        font-weight: 500;
        color: #141E2A;
    }

    .createAddress .form .default .box {
        width: 24px;
        height: 24px;
        border: 2px solid #141E2A;
        border-radius: 50%;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .createAddress .form .default .box .check {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #141E2A;
    }

    .createAddress .form .default input {
    }

    .createAddress .form .group .label {
        width: 580px;
        font-size: 30px;
        font-weight: 400;
        color: #141E2A;
    }

    .createAddress .form .group .input {
        margin-top: 7px;
        width: 580px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #E5E5E5;
        padding: 17px 0;
    }

    .createAddress .form .group .input input, .createAddress .form .group .input textarea {
        display: flex;
        flex: 1;
        border: 0;
    }

    .createAddress .form .group .input textarea {
        height: 150px;
    }

    .createAddress .form .group .input input::-webkit-input-placeholder, .createAddress .form .group .input input::-moz-placeholder, .createAddress .form .group .input input::-ms-input-placeholder, .createAddress .form .group .input textarea::-webkit-input-placeholder, .createAddress .form .group .input textarea::-moz-placeholder, .createAddress .form .group .input textarea::-ms-input-placeholder {
        color: #C7C7C7;
        font-weight: 400;
        font-size: 30px;
    }

    .createAddress .form .group .input .city {
        color: #333333;
        font-weight: 400;
        font-size: 24px;
    }

    .createAddress .form .group .input .cityNone {
        color: #969696;
        font-weight: 400;
        font-size: 24px;
    }

    .createAddress .form .group .input .icon {
        width: 8px;
        height: 18px;
    }

    .createAddress .footer {
        position: fixed;
        bottom: 0;
        background: #FFFFFF;
        width: 690px;
        height: 120px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 40px;
    }

    .createAddress .footer span {
        width: 100%;
        height: 80px;
        border: 1px solid #707070;
        background: linear-gradient(125deg, #3A3D43 0%, #202327 100%);
        border-radius: 40px;
        font-size: 30px;
        font-weight: bold;
        color: #E2BE93;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
