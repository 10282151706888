<template>
    <van-popup class="model" :close-on-click-overlay="false" @click-overlay="closeModal" v-model="modal">
        <div class="provinceBody">
            <div class="title">是否确认选择此礼品?</div>
            <select class="item" v-model="selectProvince" @change="changeProvince($event)">
                <option v-for="item in province" :value="item.id">{{item.name}}</option>
            </select>
            <select class="item" v-model="selectCity" @change="changeCity($event)">
                <option v-for="item in city" :value="item.id">{{item.name}}</option>
            </select>
            <select class="item" v-model="selectArea" @change="changeArea($event)">
                <option v-for="item in area" :value="item.id">{{item.name}}</option>
            </select>
            <div class="group">
                <div class="cancel" @click="closeModal">取消</div>
                <div class="sure" @click="submitModal">确认</div>
            </div>
        </div>
    </van-popup>
</template>

<script>
    import {getProvinceList} from "../api/data";
    import _ from "lodash";
    import {Toast} from "vant";

    export default {
        name: "ProvinceModal",
        props: ['modal', 'title'],
        async beforeMount() {
            let params = {id: 0};
            let res = await getProvinceList(params);
            if (_.toInteger(res.code) === 1) {
                let provinceList = res.data.data;
                provinceList.unshift({id: 0, name: '请选择省份'})
                this.province = provinceList;
            } else {
                Toast.fail(res.msg);
            }
        },
        data: function () {
            return {
                province: [{id: 0, name: '请选择省份'}],
                city: [{id: 0, name: '请选择城市'}],
                area: [{id: 0, name: '请选择区县'}],
                selectProvince: 0,
                selectProvinceName: 0,
                selectCity: 0,
                selectCityName: 0,
                selectArea: 0,
                selectAreaName: 0,
            }
        },
        methods: {
            submitModal() {
                this.$emit('submitModal', this.selectProvince, this.selectCity, this.selectArea, this.selectProvinceName, this.selectCityName, this.selectAreaName)
            },
            closeModal() {
                this.$emit('closeModal')
            },
            async changeProvince(e) {
                let selectIndex = e.target.selectedIndex;
                if (selectIndex === 0) {
                    this.city = [{id: 0, name: '请选择城市'}];
                    this.area = [{id: 0, name: '请选择区县'}];
                } else {
                    let province = this.province;
                    this.selectProvince = e.target.value;
                    this.selectProvinceName = province[selectIndex].name;
                    let params = {id: e.target.value};
                    let res = await getProvinceList(params);
                    if (_.toInteger(res.code) === 1) {
                        let cityList = res.data.data;
                        cityList.unshift({id: 0, name: '请选择城市'})
                        this.city = cityList;
                    } else {
                        Toast.fail(res.msg);
                    }
                    this.selectArea = 0;
                    this.area = [{id: 0, name: '请选择区县'}];
                }
            },
            async changeCity(e) {
                let selectIndex = e.target.selectedIndex;
                if (selectIndex === 0) {
                    this.selectArea = 0;
                    this.area = [{id: 0, name: '请选择区县'}];
                } else {
                    let city = this.city;
                    this.selectCity = e.target.value;
                    this.selectCityName = city[selectIndex].name;
                    this.selectArea = 0;
                    this.area = [{id: 0, name: '请选择区县'}];
                    let params = {id: e.target.value};
                    let res = await getProvinceList(params);
                    if (_.toInteger(res.code) === 1) {
                        let areaList = res.data.data;
                        areaList.unshift({id: 0, name: '请选择区县'})
                        this.area = areaList;
                    } else {
                        Toast.fail(res.msg);
                    }
                }
            },
            changeArea(e) {
                let selectIndex = e.target.selectedIndex;
                if (selectIndex !== 0) {
                    this.selectAreaName = this.area[selectIndex].name;
                    this.selectArea = e.target.value;
                }
            },
        }
    }
</script>

<style scoped>
    .model {
        background: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .model .provinceBody {
        width: 612px;
        background: #FFFFFF;
        border-radius: 16px;
        box-sizing: border-box;
        padding: 40px;
    }


    .model .provinceBody .title {
        width: 100%;
        font-size: 36px;
        font-weight: bold;
        line-height: 36px;
        color: #171A1D;
        text-align: center;
        margin: 30px 0 60px 0;
    }

    .model .provinceBody .item {
        margin-top: 30px;
        width: 100%;
        height: 80px;
        background: linear-gradient(125deg, #3A3D43 0%, #202327 100%);
        border-radius: 40px;
        font-size: 30px;
        font-weight: 500;
        color: #E2BE93;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        box-sizing: border-box;
        padding: 0 20px 0 20px;
        outline: none;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
    }

    .model .provinceBody select::-ms-expand {
        display: none;
    }

    .model .provinceBody .group {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin: 50px 0;
    }

    .model .provinceBody .group .sure {
        flex: 1;
        height: 80px;
        border: 2px solid #707070;
        background: linear-gradient(125deg, #3A3D43 0%, #202327 100%);
        border-radius: 40px;
        font-size: 30px;
        font-weight: 500;
        color: #E2BE93;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
    }

    .model .provinceBody .group .cancel {
        flex: 1;
        height: 80px;
        border: 2px solid #222529;
        border-radius: 40px;
        font-size: 30px;
        font-weight: 500;
        color: #212429;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
    }
</style>
